import {Component, inject} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-a-propos',
  template: `
    <app-a-propos-desktop *ngIf="!utilsService.isMobile" (openCV)="openCV()"></app-a-propos-desktop>
    <app-a-propos-mobile *ngIf="utilsService.isMobile" (openCV)="openCV()"></app-a-propos-mobile>
  `
})
export class AProposComponent {
  utilsService = inject(UtilsService);

  openCV() {
    const fileName = this.utilsService.getActiveLanguage() == 'fr' ? 'ERNOULD_THEO_CV.pdf' : 'ERNOULD_THEO_RESUME.pdf';
    const url = `${window.location.origin}/assets/CV/${fileName}`;
    window.open(url, '_blank');
  }
}
